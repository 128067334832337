@import "../../style/colors";

.rwm-home {
  display: flex;
  flex-wrap: wrap;

  .device {
    margin-right: 20px; }

  .ant-card-body {
    padding: 14px; } }
