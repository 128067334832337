@import "../../style/colors";

.main-header {
  position: fixed;
  width: 100%;
  height: 50px;
  background: $white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 2;
  border-bottom: 2px solid $grey-medium;

  &_title {
    cursor: pointer;

    span {
      &:first-child {
        font-weight: 700; }
      &:last-child {
        font-weight: 200; } } }

  .ant-typography {
    margin: 0; }

  &__link-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin:  0 0 0 40px;

    &_links {
      display: flex; }

    &_side-actions {
      display: flex;
      height: 100%;
      align-items: center; } }



  &__link-item {
    position: relative;
    padding: 0 10px;
    margin: 0 10px;

    display: flex;
    align-items: center;

    color: $grey-darker;
    font-weight: 600;

    cursor: pointer;

    &_name {
      font-size: 14px; }

    &_icon {
      margin-right: 8px;
      font-size: 20px; }

    &--active {
      color: black;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: black;

        border-radius: 3px;

        bottom: 0;
        left: 50%;

        transform: translateX(-50%); } }

    &:hover {
      color: #8a8a8a; } } }




.second-header {
  @extend .main-header; }

.log-btn {
  color: #000000 !important; }
