$white: #ffffff;

$grey-light: #f4f4f4;
$grey-medium: #e7e6e6;
$grey-darker: #b7b7b7;

$green-light: #43d787;
$green-darker: #1f8a5d;

$orange: #f9461c;

$black: #353535;
