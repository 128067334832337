.client-modal {
  width: 40vw !important;

  .ant-modal-footer {
    .ant-btn {
      &:first-child {
        display: none; } } }

  &_card {
    .section {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      &_title {
        font-weight: 600;
        font-size: 18px;
        margin-right: 15px;
        margin-bottom: 0; }

      &_text {
        font-size: 16px;
        word-break: break-all;
        padding-top: 1px; }

      &_btn {
        margin-left: 15px; } } } }


