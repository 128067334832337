.login {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_title {
      width: 100%;
      text-align: center; }

    .login-form {
      width: 100%;
      &_btn {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px; } } } }

