@import "../../style/colors";

.rwm-device {
  //height: 100%
  height: 90vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 25px; }

  &::-webkit-scrollbar-track {
    border-radius: 10px; }

  &::-webkit-scrollbar-thumb {
    background: $orange;
    border-radius: 20px;
    border: 10px solid #f4f4f4; }

  &__navigation-wrapper {
    width: 100%;
    margin-bottom: 10px;

    display: flex;
    align-items: center; }

  &__navigation {
    &_arrow {
      position: absolute;
      width: 70px;
      display: flex;
      align-items: center;
      color: $black;
      cursor: pointer;

      .anticon {
        font-size: 13px;
        margin-right: 5px;
        transition: 0.3s; }

      span {
        font-size: 20px; }

      &:hover {
        .anticon {
          margin-right: 7px; } } }

    &_name {
      width: 100%;
      display: flex;
      justify-content: center;
      color: $black;
      font-size: 28px;
      font-weight: 600;

      span {
        &:first-child {
          color: $grey-darker;
          margin-right: 10px; } } } }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(4, 1fr)
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    @media only screen and (max-width: 1490px) {
      grid-template-columns: repeat(3, 1fr); }

    @media only screen and (max-width: 1135px) {
      grid-template-columns: repeat(2, 1fr); }

    .device-desc {
      grid-area: 1 / 1 / 3 / 3; } }

  &_content {
    .ant-descriptions-item-label {
      font-size: 18px; }

    .ant-descriptions-item-content {
      font-size: 16px; }

    .device-desc {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding: 25px;
      border-radius: 4px;

      &__upper-actions {
        position: absolute;
        right: 20px;
        top: 38px;

        display: flex;
        flex-direction: row-reverse;

        & > div {
          margin-left: 20px; } }

      &__edit {
        position: absolute;
        top: 12px;
        left: 200px;
        width: 30px;
        height: 30px;
        font-size: 16px;
        color: #6b6b6b;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;

        background: $grey-light;
        cursor: pointer;

        &:hover {
          background: $grey-medium;
          color: $orange; } }

      &__header {
        position: relative;
        font-size: 30px;
        font-weight: 600;
        color: $grey-darker;
        margin-bottom: 20px; }

      &__params {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px; }

      &_content {
        display: flex;
        align-items: center;
        font-size: 16px;
        height: 40px;
        margin-bottom: 15px;

        &_name {
          font-weight: 600;
          margin-right: 10px;
          font-size: 22px; }

        &_desc {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 18px;
          padding-top: 5px; }

        &_field {
          width: 100%;
          position: relative;

          .error-message {
            position: absolute;
            width: 100%;
            bottom: -38px;
            font-size: 13px;
            display: flex;
            justify-content: flex-end;
            font-style: italic;
            color: #ff6d45; }

          .error-input {
            border: 1px solid #ff6d45 !important; } } }

      &_actions {
        display: block;
        width: 100%;

        &_item {
          height: 40px;
          display: flex;
          align-items: center;

          &_title {
            font-style: italic;
            margin-right: 10px;
            font-size: 14px; }

          &_tool {
            width: 100%;

            .select-power {
              min-width: 80px; } } }

        .update-actions {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          .ant-btn {
            margin-right: 15px; } } }

      &__check-status {
        margin-top: 4px; }

      &__button-actions-wrapper {
        display: flex;
        button {
          margin-right: 10px; } } }

    .config-card {
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 1px 4px 0 rgba(21,27,38,.08);
      transition: box-shadow 100ms,transform 100ms,background-color 100ms,border-color 100ms;
      min-height: 210px;

      &:hover {
        box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
        transform: translateY(-1px); }
      .ant-card-body {
        padding: 14px; }

      &_content {
        margin-bottom: 15px;

        &_item {
          border-bottom: 1px solid $grey-darker;
          display: flex;

          &_name, &_desc {
            padding: 3px; }

          &_name {
            width: 45%;
            font-style: italic;
            font-size: 17px;
            border-right: 1px solid $grey-darker;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

          &_desc {
            width: 55%; } } }

      &_actions {
        display: flex;
        justify-content: space-between; } }

    .add-config {
      display: block;
      min-height: 248px;
      width: 100% !important;
      color: darken($grey-darker, 20%) !important;
      border: 1px solid darken($grey-darker, 20%);
      padding: 40px !important;

      &_icon {
        font-size: 30px !important; } } }


  &__software-wrapper {
    max-height: 400px;
    overflow: auto;
    &_row {
      padding: 3px;
      border-bottom: 1px solid #e0e0e0;
      max-width: 600px;

      &:last-child {
        border: none; } } }

  .ant-btn {
    padding: 4px 8px !important;
    margin-top: 10px; } }

.error-input {
  .ant-input {
    border: 1px solid #ff6d45 !important; } }
