@import "../../style/colors";

.terminal {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;

  &_content {
    height: 50px;
    width: 100%;
    position: relative;
    padding-left: 10px;

    &_header {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      padding: 5px;

      .clear-btn {
        position: absolute;
        display: flex;
        top: 5px;
        left: 430px;
        color: #ffffff;
        font-weight: 600;

        &:hover, &:active, &:focus {
          color: $orange; } }

      .scroll-buttons {
        position: absolute;
        display: flex;
        top: 10px;
        left: 348px;

        &_btn {
          display: flex;
          margin-right: 7px;
          align-items: center;
          justify-content: center;
          background: transparent;
          color: #ffffff;
          width: 30px;
          height: 20px;

          &:last-child {
            padding-left: 1px; }

          &:hover, &:active, &:focus {
            background: transparent; } } }

      .terminal-tabs {
        margin-top: 35px;
        position: absolute;
        top: -27px;
        left: 20px;
        width: 100%;
        overflow: visible;

        &__list {
          overflow-y: auto;
          height: 92%;
          margin-bottom: 10px;
          font-weight: 400;

          /* width */
          &::-webkit-scrollbar {
            width: 10px; }
          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #646464;
            border-radius: 10px; }
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $orange;
            border-radius: 10px; } }

        &_tab {
          .clear-tab {
            color: #b8b8b8;
            display: flex;
            justify-content: flex-end;
            border: 1px solid #b8b8b8;
            padding: 2px !important;
            height: 20px;
            line-height: 1.2;
            font-size: 12px;
            margin-bottom: 10px; } }

        .ant-tabs-tabpane {
          max-width: 98%; }


        .ant-tabs-nav {
          color: #ffffff;
          font-weight: 600;
          margin-bottom: 10px;
          margin-left: 130px;

          &:before, &:after {
            display: none; } }


        .ant-tabs-content {
          height: 440px;
          color: #ffffff;
          font-size: 15px;
          font-weight: 600; }

        .ant-tabs-tab {
          padding: 2px 0;
          font-size: 15px !important; } }


      .terminal-btn {
        display: flex !important;
        font-weight: 600 !important;
        color: #000 !important;
        cursor: pointer;
        z-index: 99;

        &_badge {
          margin-left: 5px;

          .ant-badge-count {
            min-width: 15px;
            height: 15px;
            line-height: 1.3;
            padding: 0 !important;
            font-weight: 600; } }

        .text {
          font-size: 16px; }

        .icon {
          border-radius: 2px !important;
          width: 25px;
          height: 20px;
          border: 1px solid #000000;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-right: 7px; } } } } }

.visible {
  height: 500px;
  background: #1a1a1a;
  transition: .5s;

  &_content {
    .terminal-btn {
      color: #ffffff !important;

      .icon {
        border: 1px solid #ffffff; } } } }

