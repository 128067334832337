.user-settings {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #eee;
  padding: 20px;
  min-height: 300px;

  .settings-elem {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .ant-form-item {
      display: flex;
      gap: 10px;
      margin: 0;

      .ant-form-item-label {
        font-weight: bold;

        label {
          color: rgba(0, 0, 0, 0.65); } }

      .ant-form-item-control {
        position: relative;
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            min-width: 300px; } } } }

    .settings-elem_row {
      display: flex;
      gap: 10px;

      .update-actions {
        display: flex;
        gap: 10px;
        margin-top: 4px;

        &.disabled {
          opacity: 0.5;
          pointer-events: none; } } } } }

