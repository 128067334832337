@import "../../style/colors";

.device {
  margin-bottom: 25px !important;
  border-radius: 4px;
  max-width: 500px;
  min-width: 330px;

  box-shadow: 0 1px 4px 0 rgba(21,27,38,.08);
  transition: box-shadow 100ms,transform 100ms,background-color 100ms,border-color 100ms;

  &:hover {
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
    transform: translateY(-1px); }

  &_indicator {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_last-time {
      display: flex;
      margin-right: 10px;
      font-style: italic; }

    &_on, &_off {
      width: 15px;
      height: 15px;
      border-radius: 50%; }

    &_on {
      background: #28a745; }

    &_off {
      background: #dc3545; }

    &_wifi {
      margin-right: 10px;

      svg {
        font-size: 16px;
        path {
          fill: #28a745; } }

      &--off {
        opacity: .4;
        svg {
          font-size: 16px;
          path {
            fill: #999999; } } } } }


  &_row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $grey-darker;

    &_name, &_desc {
      padding: 10px; }

    &_name {
      width: 35%;
      font-style: italic;
      border-right: 1px solid $grey-darker; }

    &_desc {
      width: 65%; } }

  &_actions {
    display: flex;
    margin-top: 20px;

    &_more {
      width: 35%; }

    &_item-slider {
      width: 150px;
      display: flex;
      align-items: center;

      &__slider {
        width: 90px;
        margin-left: 10px !important; } }

    &_aside {
      width: 65%;
      display: flex;
      justify-content: flex-end;

      &_item {
        margin-left: 15px;
        display: flex;
        align-items: center;

        &_select {
          min-width: 100px; } } } } }
