@import "../../style/colors";
.status-modal {
  width: 95vw !important;
  //height: 90vh !important

  .ant-card-body {
    max-height: 600px;
    overflow: auto;

    div {
      margin-bottom: 10px; } }

  .ant-modal-footer {
    .ant-btn {
      &:first-child {
        display: none; } } }

  .ant-dropdown-trigger {
    margin-bottom: 10px; }

  &_card {
    .section {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;

      &_title {
        font-weight: 600;
        font-size: 18px;
        margin-right: 15px;
        margin-bottom: 0; }

      &_text {
        font-size: 16px;
        word-break: break-all;
        padding-top: 1px; }

      &_btn {
        margin-left: 15px; } } }

  pre {
    font-size: 13px; }

  .ant-table {
    tr {
      th, td {
        padding: 8px; } } } }

