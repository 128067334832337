@import "../../style/colors";

.templates-table {
  &__table {
    &_expand-wrapper {
      height: 400px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px; }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #d3d3d3;
        border-radius: 10px; }

      &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 10px; } } } }
