.settings {
  .users-table {
    width: 100%; }

  &_dropdown {
    &_btn {
      margin-bottom: 20px;
      width: 100px !important; }


    &_form {
      &_table {
        .ant-table-tbody, .ant-table-thead {
          font-size: 16px; } } } } }

