.add-card {
  width: 450px;

  .ant-input, .ant-btn, .ant-select {
    font-size: 16px; }

  &_section {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    &_select {
      min-width: 120px;
      width: 250px; }

    &_btn {
      font-size: 14px; } } }
