.ant-modal-wrap {
  display: flex;
  align-items: center; }

.config-modal {
  width: calc(100vw - 44px) !important;
  top: 0 !important;
  padding-top: 24px;

  .ant-modal-body {
    padding-bottom: 60px; }

  .ant-modal-footer {
    position: absolute;
    width: 100%;
    bottom: 0; }

  &_content {

    .content-field {
      position: relative;
      width: 100%;

      .error-message {
        position: absolute;
        width: 100%;
        bottom: -18px;
        display: flex;
        justify-content: flex-end;
        font-style: italic;
        color: #ff6d45; }

      .ant-input-group-addon {
        width: 50%; } } } }





