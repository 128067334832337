@import "../../style/colors";

.device-indicators {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;

  &__separator {
    margin: 0 10px;
    font-size: 16px;
    color: $grey-darker; }

  &__last-time {
    display: flex;
    font-style: italic; }

  &__on, &__off {
    width: 15px;
    height: 15px;
    border-radius: 50%; }

  &__on {
    background: #28a745;
    margin-right: 10px; }

  &__off {
    background: #dc3545;
    margin-left: 10px;
    margin-right: 10px; }

  &__wifi {
    margin-right: 10px;
    svg {
      font-size: 16px;
      path {
        fill: #28a745; } }

    &--off {
      opacity: .4;
      svg {
        font-size: 16px;
        path {
          fill: #999999; } } } }
  &__storage {
    margin-right: 10px; } }
