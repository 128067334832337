@import './style/colors';

html {
  scroll-behavior: smooth; }

.rwm-wrapper {
  min-height: calc(100vh - 50px);
  padding-top: 50px; }

.rwm-content {
  @extend .rwm-wrapper;
  background: $grey-light;
  padding: 20px; }

