@import "../../style/colors";

.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &_code {
    font-size: 40px;
    font-weight: 300;
    color: #969696;
    line-height: 50px; }

  &_text {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #646464; }

  .ant-result {
    background-color: transparent;
    padding: 10px 32px; } }
